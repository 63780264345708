.roadmapContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-top: 5rem;
    position: relative;
    min-height: 60rem;
    left:-12px;
    width: 100%;
}

.roadmapBackground {
    position: absolute;
    z-index: 1;
    width: 110%;
    height: auto;
    opacity: .6;
    padding: 0 !important;



}

.roadmapQuarterContainer {
    background: linear-gradient(90deg, rgba(98, 12, 2, 1) 0%, rgba(156, 55, 0, 1) 100%);
    padding: 2rem 1rem;
    border-radius: 15px;
    color: #f1f1f1;
    margin-bottom: 3rem;

}

.roadmapQuarterContainer div:not(:last-child) {
    margin-bottom: 1.5rem;

}

.roadmapTextContainer {
    position: relative;
    z-index: 100;
    width: 65%;
    margin-top: 4rem;
}

.roadmapTextHeading {
    font-size: 3rem;
    color: #620C02;
    line-height: 2rem;
}

.roadmapTextParagraph {
    font-size: 1.2rem;
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
}

.roadmapTextSmallHeading-0 {
    margin-top: 1rem;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1.2rem;
    line-height: 2.5rem;
    width: 80%;
}

.roadmapTextSmallHeading-1 {
    margin-top: 4rem;
    font-size: 0.8rem;
    font-weight: bold;
}

.roadmapTextSmallHeading-2 {
    font-family: 'Roboto', sans-serif;
    font-size: 1.4rem;
    font-weight: bold;
}

.roadmapTextSmallHeading-2 {
    font-family: 'Roboto', sans-serif;
    font-size: 1.4rem;
    font-weight: bold;
}

.roadmapBold {
    font-weight: bold !important;
}

.roadmapButtonsContainer {
    margin-bottom: 2rem;
}

.roadmapButtonsContainer button {
    font-family: 'Roboto', sans-serif;
    background: linear-gradient(90deg, rgba(98, 12, 2, .5) 0%, rgba(156, 55, 0, .5) 100%);
    border: none;
    padding: .5rem 3rem;
    color: #f1f1f1;
    margin-bottom: 1rem;
    font-size: 1.2rem;
    width: 100%;
    border-radius: .2rem;
    text-align: center;
    transition: .2s all;
}

.roadmapButtonsContainer button:hover {
    background: linear-gradient(90deg, rgba(98, 12, 2, 1) 0%, rgba(156, 55, 0, 1) 100%);
}


@media (max-width: 992px) {
    .roadmapTextContainer {
        width: 75%;
    }

    .roadmapTextSmallHeading-0 {
        font-size: 1rem;
        line-height: 1rem;
        width: 90%;
    }

    .roadmapTextParagraph {
        font-size: .8rem;
    }

    .roadmapButtonsContainer button {
        font-size: .8rem;
        padding: 1rem 0 !important;
    }
}

