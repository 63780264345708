.logoContainer {
    margin-right: .6rem;
}

.logoContainer img {
    width: 30px;
    height: auto;
    cursor: pointer;
}

@media (max-width: 992px) {
    .logoContainer img {
        width: 20px;
    }
}
