.faqsAnswer {
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    width: 50%;
    position: revert;
    color: black !important;

}
.faqsAnswer li{
    line-height: 1.2rem;
}
.faqsContainer button {
    border: none;
    background-color: transparent;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    font-size: 1.2rem;
}

.faqsContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    margin-top: .5rem;
    margin-bottom: .5rem;
}
