@import url('https://fonts.googleapis.com/css2?family=Puritan&display=swap');

.bandMemberContainer {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;

}

.bandMemberContainer img {
    height: auto;
    max-height: 250px;
    max-width: 100%;
    border-radius: 10px;

}

.bandMemberText {
    font-family: 'Roboto', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    font-size: 14px;
    bottom: 10px;
    font-weight: 700;
    padding: .5rem .5rem;
    text-align: center;
    border-radius: 5px;
    background: rgb(98, 12, 2);
    background: linear-gradient(90deg, rgba(98, 12, 2, 1) 0%, rgba(156, 55, 0, 1) 100%);
    color: white;
    word-wrap: break-word;
    width: 100%;
    height: 3rem;
}

@media (max-width: 1600px) {
    .bandMemberText {
        font-size: 12px;
    }
}

@media (max-width: 992px) {
    .bandMemberContainer img {
        max-height: 420px;
    }
}

@media (max-width: 768px) {
    .bandMemberContainer img {
        max-height: 1200px;
        max-width: 85%;
    }

    .bandMemberText {
        max-width: 85%;
    }
}

@media (max-width: 576px) {
    .bandMemberContainer img {
        max-width: 90%;

    }

    .bandMemberText {
        max-width: 80%;
    }
}
