.mintInfoContainer {
    max-width: 100vw;
    max-height: 75vh;
    position: relative;
    margin-bottom: 10rem;
}

.mintInfoClaw {
    position: absolute;
    top: 0px;
    left: -100px;
    height: auto;
    width: 100rem;
    z-index: 55;
}

.mintContainerMainText {
    position: relative;
    justify-content: center;
    align-items: center;
    top: 50px;
    left: 300px;
    z-index: 55;

}

.mintContainerMainTextFirstParagraph {
    position: absolute;
    top: 165px;
    left: 575px;
    font-size: 1.5rem;
}

.mintContainerMainTextSecondParagraph {
    position: absolute;
    top: 190px;
    font-size: 1.5rem;
    left: 535px;
}

.mintContainerMainText img {
    height: auto;
    width: 75rem;
    position: relative;
    top: 0;
    left: 0;
}

.mintContainerSubText {

}

.mintContainerSubText img {
    height: auto;
    width: 75rem;
    position: absolute;
    top: 100px;
    left: -50px;
    z-index: 99;

}

.mintContainerSubTextFirstParagraph {
    position: absolute;
    z-index: 100;
    top: 585px;
    left: 55px;
    font-size: 1.2rem;
}

.mintContainerSubTextSecondParagraph {
    position: absolute;
    z-index: 100;
    top: 610px;
    left: 55px;
    font-size: 1.2rem;
}

.mintInfoClouds {
    position: absolute;
    width: 100rem;
    height: auto;
    left: 0;
    top: -300px;
    z-index: 55;
}

.phoenix1 {
    position: absolute;
    top: 0;
    right: 0;
    width: 100rem;
    height: auto;
    z-index: 55;
}

.phoenix2 {
    position: absolute;
    top: 0;
    right: 0;
    width: 100rem;
    height: auto;
    z-index: 55;
}

.phoenix3 {
    position: absolute;
    top: 0;
    right: 0;
    width: 100rem;
    height: auto;
    z-index: 55;
}

.phoenix4 {
    position: absolute;
    top: 0;
    right: 0;
    width: 100rem;
    height: auto;
    z-index: 55;
}


.phoenix5 {
    position: absolute;
    top: 0;
    right: 0;
    width: 100rem;
    height: auto;
    z-index: 55;
}

.phoenix6 {
    position: absolute;
    top: 0;
    right: 0;
    width: 100rem;
    height: auto;
    z-index: 55;
}

.feathers1 {
    position: absolute;
    top: 100px;
    right: 0;
    width: 100rem;
    height: auto;
    z-index: 55;
}

.feathers2 {
    position: absolute;
    top: 100px;
    right: 0;
    width: 100rem;
    height: auto;
    z-index: 55;
}

.feathers3 {
    position: absolute;
    top: 100px;
    right: 0;
    width: 100rem;
    height: auto;
    z-index: 55;
}

.mountain {
    position: absolute;
    top: -100px;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 50;
}

.parallaxSkinrunnersMainLogo {
    position: absolute;
    top: 0;
    left: 400px;
    width: 950px;
}

@media (max-width: 1500px) {
    .mintInfoContainer {
        margin-bottom: 10rem;
    }

    .parallaxSkinrunnersMainLogo {
        left: 175px;
    }

    .feathers1 {
        top: -300px;
        right: 200px;
    }

    .feathers2 {
        display: none;
    }

    .feathers3 {
        display: none;
    }

    .phoenix1 {
        top: 0px;
        width: 75rem;
        height: auto;
    }

    .phoenix2 {
        display: none;
    }

    .phoenix3 {
        display: none;
    }

    .phoenix4 {
        display: none;
    }

    .phoenix5 {
        display: none;
    }

    .phoenix6 {
        display: none;
    }

    .mountain {
        height: 120%;
        width: auto;
        min-width: 100%;
    }

    .mintInfoClaw {
        width: 50rem;
        left: -50px;
    }


    .mintContainerMainText {
        top: 0px;
        left: 0;
    }

    .mintContainerSubText img {
        top: 0;
        width: 60rem;
    }

    .mintContainerSubTextFirstParagraph {
        top: 385px;
        font-size: 1rem;
        left: 30px;
    }

    .mintContainerSubTextSecondParagraph {
        top: 405px;
        font-size: 1rem;
        left: 30px;
    }

    .mintContainerMainText img {
        position: relative;
        top: 400px;
        left: 300px;
        width: 50rem;
    }

    .mintContainerMainTextFirstParagraph {
        top: 510px;
        left: 685px;
        font-size: 1rem;
    }

    .mintContainerMainTextSecondParagraph {

        top: 525px;
        font-size: 1rem;
        left: 650px;
    }
}

@media (max-width: 1100px) {
    .parallaxSkinrunnersMainLogo {
        width: 800px;
        left: 125px;
    }
}

@media (max-width: 992px) {
    .parallaxSkinrunnersMainLogo {
        width: 750px;
        left: 100px;
    }
}

@media (max-width: 900px) {
    .parallaxSkinrunnersMainLogo {
        width: 650px;
        left: 100px;
    }

    .mintContainerMainText img {
        position: relative;
        top: 400px;
        left: 150px;
        width: 50rem;
    }

    .mintContainerMainTextFirstParagraph {
        top: 510px;
        left: 540px;
        font-size: 1rem;
    }

    .mintContainerMainTextSecondParagraph {

        top: 525px;
        font-size: 1rem;
        left: 500px;
    }
}

@media (max-width: 750px) {
    .mountain {
        left: -25px;
        width: 100rem;
    }

    .mintContainerMainText img {
        position: relative;
        top: 400px;
        left: 0px;
        width: 50rem;
    }

    .mintContainerMainTextFirstParagraph {
        top: 510px;
        left: 390px;
        font-size: 1rem;
    }

    .mintContainerMainTextSecondParagraph {

        top: 525px;
        font-size: 1rem;
        left: 350px;
    }
}

@media (max-width: 600px ) {
    .parallaxSkinrunnersMainLogo {
        width: 750px;
        left: 10px;
    }

    .mintContainerMainText img {
        position: relative;
        top: 400px;
        left: -225px;
        width: 50rem;
    }

    .mintContainerMainTextFirstParagraph {
        top: 510px;
        left: 165px;
        font-size: 1rem;
    }

    .mintContainerMainTextSecondParagraph {

        top: 525px;
        font-size: 1rem;
        left: 130px;
    }
}
