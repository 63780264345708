.faqSectionContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    position: relative;
    margin-top: 5rem;
}

.faqSectionContainer h2 {
    color: #620C02;
    font-size: 3rem;
}

.faqSectionParallax {
    top:-650px;
    position: absolute;
    bottom: 0;
    left: 350px;
}
.faqTitle {

}

.faqSectionParallax img {
    height: 900px;
    width: auto;
    position: static;
}


@media (max-width: 768px) {
    .faqSectionParallax {
        top: 100px;
        position: absolute;
        bottom: 0;
        left: .1px;
    }

    .faqSectionParallax img {

        height: 500px;
        width: auto;
    }
}
