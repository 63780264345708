.treasureHuntContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-top: 150px;
    margin-bottom: 5rem;

}

.treasureHuntCard {
    width: 33%;
    height: auto;
    z-index: 1;
}

.treasureHuntText {
    width: 75%;
}

.treasureHuntText h2 {
    color: #620C02;
    font-size: 3rem;
    line-height: 1.5rem;

}

.treasureHuntText p {
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 1.2rem;
    margin-top: 5rem;
    margin-bottom: 3rem;

}

.crowSectionParallaxContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    position: relative;
}

.crowSectionParallax {
    position: absolute;
    top: 50px;
    right: 00px;
}

.crowSectionParallax img {
    height: 500px;
    width: auto;
    opacity: 1;
}

@media (max-width: 992px) {
    .treasureHuntCard {
        width: 50%;
    }

    .treasureHuntText {
        width: 100%;
    }

    .treasureHuntText h2 {
        line-height: 2.5rem;
    }
}

@media (max-width: 768px) {
    .treasureHuntCard {
        width: 100% !important;
    }

    .crowSectionParallax {
        right: 150px;
    }

    .crowSectionParallax img {
        height: 250px;
        margin-top: 250px;
    }
}

@media (max-width: 576px) {
    .treasureHuntCard {
        width: 100% !important;
    }
}
