.videoSectionContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 4rem;
    margin-top: 150px;
}

.videoSectionHeading {
    color: #620C02;
    font-size: 3rem;
    line-height: 2.4rem;
    margin-bottom: 1rem;
    text-align: center;

}

.videoParagraphFirst {

}

.videoSectionParagraph {
    font-family: 'defaultFont', sans-serif;
    font-weight: bold;
    font-size: 1.2rem;
    text-align: center;
    margin-top: 5rem;
    margin-bottom: 1rem;
}

.accordionSection {
    font-family: 'defaultFont', sans-serif;
    font-weight: normal;
    font-size: 1rem;
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;
    text-align: justify;

}

.accordionSection button {
    border: none;
    padding: .5rem 1rem;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, .3);
    width: 10%;
    cursor: pointer;
    transition: all .2s;
    margin-bottom: 1rem;
}

.accordionSection button:hover {
    background-color: rgba(255, 255, 255, .5);
}

.videoContainer {
    width: 75%;
    padding-bottom: 1rem;
    z-index: 1;
}

.videoMain {
    justify-content: center;
    width: 100% !important;
    height: 650px !important;
}

.videoLinksSection {
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    color: #620C02;

}

.featherSectionContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    position: relative;
}

.featherSectionParallaxRight {
    position: absolute;
    bottom: 0;
    left: 300px;
    top: -460px;
}

.featherSectionParallaxRight img {
    height: 400px;
    width: auto;
    opacity: .8;
}

.featherSectionParallaxLeft {
    position: absolute;
    bottom: 0;
    right: 350px;
    top: -450px;
}

.featherSectionParallaxLeft img {
    height: 400px;
    width: auto;
    opacity: .8;
}

@media (max-width: 768px) {
    .videoSectionContainer {
        margin-top: 75px;
        padding: 1rem 2rem;
    }

    .videoSectionHeading {
        font-size: 2rem;
    }

    .videoMain {
        height: 350px !important;
    }

    .videoSectionParagraph {
        font-size: 1rem;
    }

    .accordionSection {
    width: 95%;
    }

    .accordionSection button {
        width: 25%;
    }

    .featherSectionParallaxRight {
        position: absolute;
        bottom: 0;
        left: 60px;
        top: -450px;
    }

    .featherSectionParallaxRight img {
        height: 200px;
        width: auto;
        opacity: .8;
    }

    .featherSectionParallaxLeft {
        position: absolute;
        bottom: 0;
        right: 100px;
        top: -450px;
    }

    .featherSectionParallaxLeft img {
        height: 200px;
        width: auto;
        opacity: .8;
    }
}


@media (max-width: 450px) {
    .videoContainer {
        width: 100%;
    }

    .videoMain {
        height: 300px !important;
    }
}
