
.upcomingSectionContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    text-align: center;
    position: relative;
    margin-bottom: 4rem;

}

.upcomingSectionVideo {
    position: absolute;
    z-index: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.upcomingSectionContent {
    position: absolute;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    width: 100vw;
    height: 100vh;
    padding: 20px;
    color: #f1f1f1;

}

.upcomingSectionText {
    margin-top: 10rem;
}

.upcomingSectionContent p {
    color: #f1f1f1;
}

.skinrunnersLogoContainer {
    margin-bottom: 2rem;
}

.upcomingSectionHeading {
    color: #620C02;
    font-size: 4rem;
    line-height: 5rem;
    text-transform: uppercase;
}

.upcomingSectionList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 100px;
    margin-left: 2rem;
    margin-top: 1rem;
    font-weight: bold;
    font-size: 1.3rem;
}

.upcomingSectionList li {
    line-height: 1.5rem;
}

.upcomingSectionList li:not(:last-child) {
    margin-bottom: .5rem;
}

.upcomingSectionParallaxLeft {
    position: absolute;
    bottom: 0;
    left: 500px;
}

.upcomingSectionParallaxLeft img {
    opacity: .5;
    height: 250px;
    width: auto;
}

.upcomingSectionParallaxRight {
    position: absolute;
    bottom: 0;
    right: 350px;
}

.upcomingSectionParallaxRight img {
    opacity: .5;
    height: 350px;
    width: auto;
}

@media (max-width: 768px) {
    .upcomingSectionHeading {
        font-size: 1rem;
    }

    .upcomingSectionList {
        font-size: 1.2rem;
    }

    .upcomingSectionContainer {
        padding: 0 1rem;
    }

    .skinrunnersLogoContainer img {
        height: 150px;
        width: auto;
    }

    .upcomingSectionParallaxLeft {
        position: absolute;
        bottom: 0;
        left: 10px;
    }

    .upcomingSectionParallaxLeft img {
        opacity: .3;
        height: 250px;
        width: auto;
    }

    .upcomingSectionParallaxRight {
        position: absolute;
        bottom: 0;
        right: 10px;
    }

    .upcomingSectionParallaxRight img {
        opacity: .5;
        height: 350px;
        width: auto;
    }

    .upcomingSectionText {
        margin-top: 5rem;
        margin-bottom: 100px;
    }
}
