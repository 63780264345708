.sneakPeekContainer {
    position: relative;
    margin-bottom: 15rem;
}

.sneakPeekContainerMobile {
    display: none;
}

.sneakPeekItem {
    height: auto;
    width: 100%;
    -webkit-box-shadow: 0px 0px 30px 8px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 30px 8px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 30px 8px rgba(0, 0, 0, 0.75);
    transition: all .3s ease-out;
    position: relative;
    border-top-left-radius: 255px 15px;
    border-top-right-radius: 15px 225px;
    border-bottom-right-radius: 225px 15px;
    border-bottom-left-radius: 15px 255px;
    border: dashed 2px #41403E;

}

.sneakPeekItemContainer:hover .sneakPeekItem {
    box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.75);
}

.sneakPeekItemContainer {
    width: 20%;


}

.item1 {
    position: absolute;
    top: -450px;
    left: 500px;
    transform: rotate(-10deg);

}

.item1:hover .sneakPeekItem {
    z-index: 99999 !important;
    transform: rotate(10deg) scale(1.5);
}

.item1:hover {
    z-index: 999;
}

.item2 {
    position: absolute;
    top: -150px;
    right: 250px;
    transform: rotate(10deg);

}

.item2:hover .sneakPeekItem {
    z-index: 75 !important;
    transform: rotate(-10deg) scale(1.5);
}

.item2:hover {
    z-index: 999;
}

.item3 {
    position: absolute;
    transform: rotate(-5deg);
    right: -150px;
    top: -250px;
    z-index: 30;
}

.item3:hover .sneakPeekItem {
    z-index: 75;
    transform: rotate(5deg) scale(1.5);
}

.item3:hover {
    z-index: 999;
}

.item4 {
    position: absolute;
    transform: rotate(15deg);
    left: 0px;
    bottom: 200px;
    z-index: 30;
}

.item4:hover .sneakPeekItem {
    z-index: 99999 !important;
    transform: rotate(-15deg) scale(1.5);
}

.item4:hover {
    z-index: 999;
}

.item5 {
    position: absolute;
    transform: rotate(0deg);
    right: 750px;
    bottom: -100px;
    z-index: 30;
}

.item5:hover {
    z-index: 999;
}

.item5:hover .sneakPeekItem {
    z-index: 99999 !important;
    transform: scale(1.5);
}

.item6 {
    position: absolute;
    transform: rotate(-15deg);
    left: -200px;
    bottom: -100px;
    z-index: 30;
}

.item6:hover .sneakPeekItem {
    z-index: 99999 !important;
    transform: rotate(15deg) scale(1.5);
}

.item6:hover {
    z-index: 999;
}

.item7 {
    position: absolute;
    transform: rotate(25deg);
    right: 150px;
    top: -450px;
    z-index: 30;
}

.item7:hover .sneakPeekItem {
    z-index: 99999 !important;
    transform: rotate(-25deg) scale(1.5);
}

.item7:hover {
    z-index: 999;
}

@media (max-width: 1600px) {
    .sneakPeekItem {
        width: 80%;
    }

    .item1 {
        top: -300px;
        left: 400px;
    }

    .item3 {
        right: 0;
    }

    .item5 {
        left: 250px;
    }

    .item6 {
        left: 0;
    }

    .item7 {
        top: -350px;
        right: 250px;
    }
}



@media (max-width: 1000px) {
    .sneakPeekContainer {
        display: none;
    }

    .sneakPeekContainerMobile {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .sneakPeekContainerMobile img {
        width: 75%;
        height: auto;
        border-top-left-radius: 255px 15px;
        border-top-right-radius: 15px 225px;
        border-bottom-right-radius: 225px 15px;
        border-bottom-left-radius: 15px 255px;
        border: dashed 2px #41403E;
    }
}
