.footerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 15rem;
}

.footerLogo img {
    height: 10rem;
    width: auto;
    margin-bottom: 5rem;
}

.footerContactUs {
    color: #620C02;
    margin-bottom: 2rem;
    font-size: 1.2rem;
}

.contactUsLogo {
    display: flex;
    margin-top: 4rem;
    justify-content: center;
}

.contactUsHeader {
    line-height: 2rem;
    text-align: center;

}

.contactUsHeader h2 {
    font-size: 3rem;

}

@media (max-width: 992px) {
    .contactUsHeader {
        line-height: 3rem;
    }
}
