.communityTitle {
    margin-top: 150px;
}

.communityTitle p {
    display: block;
    color: rgb(0, 0, 0);
    font-size: 3rem;
    font-weight: 400;
    line-height: 64px;
    margin-bottom: 24px;
    text-align: center;
    color: #620C02;

}

.communityTitle a {
    display: block;
    color: rgb(0, 0, 0);
    font-size: 24px;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    margin-bottom: 24px;
    text-align: center;

}

.communityHeading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}

.communityHeading img {
    width: 50%;
    height: auto;
}

.communityHeading h2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: #620C02;
    font-size: 1.2rem;
}

.CommunityContainer {
margin: 0;
}

.communityContainer {
    margin-top: 2rem;
    box-sizing: border-box;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    max-width: 1140px;
}

@media (max-width: 768px) {
    .communityContainer {
        padding: 0 0;
    }
}
