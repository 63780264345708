.parallaxSectionContainer {
    position: relative;
    margin-top: 100px;
}

.poweredByLogo {
    display: flex;
    justify-content: center;

}

.poweredByLogo img {
    position: absolute !important;
    margin: auto !important;
    z-index: 10;
    top: -120px;
    height: auto !important;
    width: 25% !important;
}

.img1 img {
    margin-left: -1rem;
    width: 120vw;
    opacity: .9;
    display: flex;
    background-color: transparent;
    border-radius: 0;
    flex-wrap: nowrap;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    z-index: 99;
}

.img2 {
    width: 100%;
    position: relative;
    z-index: 999;
}

.img2 img {
    position: absolute;

    z-index: 999;
    right: 200px;
    bottom: -850px;
}

@media (max-width: 768px) {
    .img2 img {
        width: 400px;
        right: -150px;
        bottom: -150px;
    }
}

@media (max-width: 576px) {
    .parallaxSectionContainer img {
        margin-left: 0rem;
        width: 160vw;
    }

    .img2 img {
        width: 350px;
        right: -100px;
        bottom: -250px;
    }
}
