.bandMembersHeading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    margin-top: 100px;


}

.bandMembersHeading img {
    width: 60rem;
    height: auto;

}

.bandMembersHeading h2 {
    margin-top: 1rem;
    margin-bottom: 2rem;
    color: #620C02;
    font-size: 5rem;
    line-height: 1.5rem;
}

.bandMembersContainer {
    margin-top: 2rem;
    padding: 0 4rem;
}

@media (max-width: 1280px) {
    .bandMembersContainer {
        padding: 0 2rem;
    }
    .bandMembersHeading img {
        width:50rem;
        height: auto;
    }
    .bandMembersHeading h2 {
        font-size: .8rem;
    }
}
@media (max-width: 768px) {
    .bandMembersContainer {
        padding: 0 2rem;
        margin-top: 10rem;
    }
    .bandMembersHeading img {
        width: 20rem;
        height: auto;
    }
    .bandMembersHeading h2 {
        font-size: 2rem;
    }
    .bandMembersHeading img {
      margin-left: -10rem;
    }
}
