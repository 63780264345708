@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

@font-face {
  font-family: "cartoonFont";
  src: url("../../public/fonts/Shojumaru-Regular.ttf");
}

@font-face {
  font-family: "defaultFont";
  src: url("../../public/fonts/Programme-Regular.ttf");
}

.mainContainer {
  font-family: "cartoonFont", sans-serif;
  width: 100vw;
  overflow-x: hidden;
  min-height: 100vh;
  background-image: url("../i/background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

