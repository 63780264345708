.navbarContainer {
    display: flex;
    justify-content: space-between;
    padding: 1rem 3rem;
    position: relative;

}

.dropdownMain {
    position: absolute;
    top: 75px;
    right: 0;
    display: none;
    color: #620C02 !important;
}


.dropdown-toggle {
    color: #620C02 !important;
}

.dropdown-toggle:hover {
    color: white !important;
}

.dropdownDesktop {
    display: block;
}

.btn-link {
    color: black;
}

.btn-link:hover {
    color: white;
}

.dropdown-item a {
    font-size: 18px !important;
}

.dropdown-item:hover {
    color: black !important;
}

.dropdown-menu {
    background: none;
    z-index: 99999;
    text-align: center;
    border: none;
}

.dropdown-item {
    border: none;
    padding: 5px;
    font-family: 'defaultFont', sans-serif;
    font-weight: bold;
    color: #620C02;
    transition: .2s all;

}

.dropdown-item.disabled {
    cursor: not-allowed;
    color: white;
}

.dropdown-item:hover {
    color: white;
    background: none;
}

.btn:focus, .btn:active {
    outline: none !important;
    box-shadow: none;
}

.navbarLeft {
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbarRight {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.navbarCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbarLink {
    font-family: 'defaultFont', sans-serif;
    color: #620C02;
    font-weight: bold;
    cursor: pointer;
    margin-right: 3rem;
    transition: all .2s;
    font-size: 1.1rem;

}

.navbarLink:hover {
    color: white;

}

.navbarLink:not(:last-child) {
    margin-right: 2rem;
}

.navbarLogo {

    width: auto;
    display: flex;
}

.navbarLogo img {
    height: 100px;
    width: auto;
}

#menu__toggle {
    opacity: 0;
}

#menu__toggle:checked + .menu__btn > span {
    transform: rotate(45deg);
}

#menu__toggle:checked + .menu__btn > span::before {
    top: 0;
    transform: rotate(0deg);
}

#menu__toggle:checked + .menu__btn > span::after {
    top: 0;
    transform: rotate(90deg);
}


.menu__btn {
    position: fixed;
    top: 50px;
    right: 20px;
    width: 26px;
    height: 26px;
    cursor: pointer;
    z-index: 999;
}

.menu__btn > span,
.menu__btn > span::before,
.menu__btn > span::after {
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: black;
    transition-duration: .25s;
}

.menu__btn > span::before {
    content: '';
    top: -8px;
}

.menu__btn > span::after {
    content: '';
    top: 8px;
}

.menu__box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: -110%;
    width: 100vw;
    height: 100vh;
    margin: 0;
    text-align: center;
    padding-top: 50px;
    list-style: none;
    background: linear-gradient(165deg, rgba(253, 179, 82, 1) 0%, rgba(252, 145, 77, 1) 47%);
    box-shadow: 2px 2px 6px rgba(0, 0, 0, .4);
    transition-duration: .25s;
    z-index: 10000;
}

.menu__item {
    display: block;
    padding: 12px 24px;
    color: #333;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 600;
    text-decoration: none;
    transition-duration: .25s;
}

.menu__item:hover {
    background-color: #FB874C;
}

@media (min-width: 768px) {
    .hamburger-menu {
        display: none;
    }
}

@media (max-width: 768px) {
    .dropdownMain {

        display: block;
    }

    .dropdownDesktop {
        display: none;
    }

    .navbarCenter {
        display: none;
    }

    .navbarContainer {
        padding: 2rem 1rem;
        flex-direction: column;
    }

    .navbarLeft {
        margin-bottom: 1rem;
    }

    .navbarCenter {
        margin-bottom: 1rem;
    }

    .navbarLink {
        margin-right: 0;
    }
}

a {
    text-decoration: none;
}
