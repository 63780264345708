.communityContainer {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    background-image: linear-gradient(to right, rgb(98, 12, 2), rgb(156, 55, 0));
    width: 80%;
    height: auto;

}

.communityContainer img {
    height: 100%;
    width: 100%;
    border-radius: 20px;
}

.communityText1 {
    font-family: 'defaultFont', sans-serif;
    position: absolute;
    font-size: 1rem;
    bottom: 100px;
    font-weight: normal;
    padding: .4rem .8rem;
    border-radius: 10px;
    background: rgb(98, 12, 2);
    background: linear-gradient(to right, rgb(255, 201, 194), rgb(245, 233, 226));
    color: black;
    word-wrap: break-word;
    max-width: 80%;
}
.communityText2 {
    font-family: 'defaultFont', sans-serif;
    position: absolute;
    font-size: .9rem;
    bottom: 10px;
    font-weight: normal;
    padding: .6rem .8rem;
    border-radius: 10px;
    background: rgb(98, 12, 2);
    background: linear-gradient(90deg, rgba(98, 12, 2, 1) 0%, rgba(156, 55, 0, 1) 100%);
    color: white;
    word-wrap: break-word;
    text-align: center;
    max-width: 80%;
}

@media (max-width: 400px) {
    .communityText1 {
        font-family: 'defaultFont', sans-serif;
        position: absolute;
        font-size: .4rem;
        bottom: 70px;
        font-weight: normal;
        padding: .4rem .8rem;
        border-radius: 10px;
        background: rgb(98, 12, 2);
        background: linear-gradient(to right, rgb(255, 201, 194), rgb(245, 233, 226));
        color: black;
        word-wrap: break-word;
        max-width: 60%;
    }
    .communityText2 {
        font-family: 'defaultFont', sans-serif;
        position: absolute;
        font-size: .4rem;
        bottom: 20px;
        font-weight: normal;
        padding: .6rem .8rem;
        border-radius: 10px;
        background: rgb(98, 12, 2);
        background: linear-gradient(90deg, rgba(98, 12, 2, 1) 0%, rgba(156, 55, 0, 1) 100%);
        color: white;
        word-wrap: break-word;
        max-width: 60%;
    }
}
